<template>
    <main class="flex-1 overflow-y-auto h-full">
        <PageHead sticky
                  title="Produkte">
            <template #actions>
                <c-button :path="{ name: 'ProductAdd' }">Neu</c-button>
            </template>
        </PageHead>

        <List :items="products"
              editRouteName="ProductEdit"/>

        <p class="p-6 text-center text-base text-gray-400">
            {{ products.length }} Produkte wurden geladen.
        </p>
    </main>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Products',
    computed: {
        ...mapGetters('products', ['getProducts']),
        products() {
            return this.getProducts();
        },
    },
};
</script>
